import { useState, useEffect } from "react";
const RTL_LANGUAGES = ["ar", "fa", "he", "ur"];

export function useLanguageDirection(language: string) {
    const [direction, setDirection] = useState<"ltr" | "rtl">("ltr");

    useEffect(() => {
        setDirection(RTL_LANGUAGES.includes(language) ? "rtl" : "ltr");
    }, [language]);

    return direction;
}
